import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';


// landings
const Startup = React.lazy(() => import('../pages/landings/Startup'));
const Contact = React.lazy(() => import('../pages/landings/Contact'));
const Company = React.lazy(() => import('../pages/landings/Company'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Startup} />,
                },
                {
                    path: 'contact',
                     element: <LoadComponent component={Contact} />
                },
                {
                    path: 'about',
                    element: <LoadComponent component={Company} />
                },
            ],
        },
    ]);
};

export default AllRoutes;
